<template>
    <div id="default-layout">
        <Header />
        <notification
            v-if="response.Notification && response.Notification.content"
            :content="response.Notification.content"
        />
        <main>
            <slot />
        </main>
        <Footer />
    </div>
</template>
<script setup>
const config = useRuntimeConfig();
const { data: response } = await useFetch(config.public.baseURl + '/home');
</script>
<style lang="scss" scoped>
#default-layout {
    main {
    }
}
</style>

<template>
    <div class="header">
        <div class="md:container mx-auto px-4 lg:px-12 py-0">
            <div class="flex justify-between items-center">
                <NuxtLink to="/">
                    <div class="logo">
                        <img
                            src="~/assets/images/logo-dark.png"
                            alt="logo"
                            loading="lazy"
                        />
                    </div>
                </NuxtLink>
                <div
                    class="menu hidden lg:block"
                    :class="{ 'mobile-menu': showMenu }"
                >
                    <ul v-if="response && response.MenuItems.length">
                        <li
                            v-for="item in response.MenuItems"
                            :key="item._id"
                            :class="{ 'has-subnav': item.Submenu.length > 0 }"
                        >
                            <nuxt-link :to="item.Link">{{
                                item.Title
                            }}</nuxt-link>
                            <ul v-if="item.Submenu.length > 0" class="subnav">
                                <li
                                    v-for="submenu in item.Submenu"
                                    :key="submenu._id"
                                >
                                    <nuxt-link :to="submenu.Link">{{
                                        submenu.Label
                                    }}</nuxt-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <a class="burger-menu lg:hidden" @click="toggleMenu">
                    <span class="menu-line line-top" />
                    <span class="menu-line" />
                    <span class="menu-line line-bottom" />
                </a>
            </div>
        </div>
    </div>
</template>

<script setup>
import { watch } from 'vue';
import { useRoute } from 'vue-router';

const config = useRuntimeConfig();

const showMenu = ref(false);
const route = useRoute();

watch(route, () => {
    showMenu.value = false;
});

function toggleMenu() {
    showMenu.value = !showMenu.value;
}

const { data: response } = await useFetch(
    config.public.baseURl + '/header-menu'
);
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    width: 100%;
    border-bottom: 1px solid #e5e7eb;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    background-color: #ffffff;
    height: 80px;
    .logo {
        height: 80px;
        padding: 8px 0;
        img {
            height: 100%;
        }
    }
    .menu {
        ul {
            display: flex;
            display: flex;
            column-gap: 1.6rem;
            justify-content: flex-start;
            li {
                position: relative;
                a {
                    color: $brand-primary;
                    letter-spacing: 0.5px;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 80px;
                    display: block;
                    cursor: pointer;
                    position: relative;
                    &:before {
                        content: '';
                        width: 100%;
                        height: 1.5px;
                        background-color: $brand-primary;
                        bottom: 24px;
                        left: 0;
                        position: absolute;
                        transform: scaleX(0);
                        transform-origin: right center;
                        transition: transform 0.3s cubic-bezier(0.4, 0, 0, 1);
                        pointer-events: none;
                    }
                    &.router-link-active {
                        &:before {
                            transform: scaleX(1);
                            transform-origin: left center;
                        }
                    }
                    &:hover {
                        &:before {
                            transform: scaleX(1);
                            transform-origin: left center;
                        }
                    }
                }
                &.has-subnav {
                    padding-right: 24px;
                    position: relative;
                    &:after {
                        content: url('/images/icons/triangle-down.svg');
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 20px;
                        height: 20px;
                    }
                }
                ul.subnav {
                    display: block !important;
                    z-index: 2021;
                    white-space: nowrap;
                    height: auto;
                    position: absolute;
                    text-align: left;
                    min-width: 200px;
                    top: 100%;
                    left: 0;
                    background-color: #f4f4f3;
                    visibility: hidden;
                    li {
                        font-size: 0.875rem;
                        display: block !important;
                        margin-right: 0;
                        color: $brand-primary;
                        cursor: pointer !important;
                        padding: 0;
                        a {
                            line-height: 24px;
                            padding: 8px 16px;
                            &:before {
                                content: none;
                            }
                        }
                        &:hover {
                            background-color: $brand-secondary;
                            color: $white;
                            a {
                                color: $white;
                            }
                        }
                    }
                }
                &:hover {
                    ul.subnav {
                        visibility: visible;
                        z-index: 2021;
                    }
                    @media (max-width: 767px),
                        (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
                        ul.subnav {
                            position: relative;
                        }
                    }
                    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
                        ul.subnav {
                            position: relative;
                        }
                    }
                }
                @media (max-width: 767px),
                    (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
                    &.has-subnav {
                        &:after {
                            position: absolute;
                            right: 8px;
                            top: 10px;
                        }
                    }
                    ul {
                        padding: 0;
                    }
                }
                @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
                    &.has-subnav {
                        &:after {
                            position: absolute;
                            right: 8px;
                            top: 10px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 767px),
        (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
        height: 56px;
        .logo {
            height: 56px;
            padding: 8px 0;
            img {
                height: 100%;
            }
        }
    }
    .burger-menu {
        width: 28px;
        height: 20px;
        position: absolute;
        right: 3rem;
        z-index: 10000;
        overflow: hidden;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);

        .line-top {
            margin-top: -8px;
        }
        .line-bottom {
            margin-top: 8px;
        }

        .menu-line {
            width: 100%;
            height: 2px;
            display: block;
            background-color: $brand-primary;
            top: 50%;
            position: absolute;
            z-index: 5;
        }

        @media (max-width: 767px),
            (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
            right: 1rem;
        }

        @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
            right: 1rem;
        }
    }
    .mobile-menu {
        display: block;
        height: auto;
        background-color: #ffffff;
        position: absolute;
        width: 100%;
        left: 0;
        top: 100%;
        ul {
            display: block;
            text-align: left;
            padding: 8px 16px;
            li {
                display: block !important;
                padding: 0;
                margin: 0;
                a {
                    line-height: 32px;
                    &:before {
                        height: 0;
                    }
                }
            }
        }
    }
}
</style>
